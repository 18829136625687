<template>
<loader v-bind="{ loading }">
    <columns>
        <column>
            <div class="buttons has-addons is-rounded">
                <download-button :path="`/reports/${report.id}/download-pdf?start_date=${start_date}`" :file-name="`${report.name}.pdf`"
                    class="is-rounded is-small" left-icon="download">
                    PDF
                </download-button>
                <download-button :path="`/reports/${report.id}/download-excel?start_date=${start_date}`" :file-name="`${report.name}.xlsx`"
                    class="is-rounded is-small" left-icon="download">
                    Excel
                </download-button>
            </div>
        </column>
        <column class="is-3">
            <date-picker :value="start_date" :label="false" @input="updateStartDate" classes="is-rounded has-addons"
                placeholder="Filter by date">
            </date-picker>
        </column>
    </columns>

    <columns v-for="widget in report.widgets" :key="widget.id">
        <column>
            <component 
                :is="`report-widget-${widget.type}`" 
                :rows="rows" 
                :date-key="'start_date'" 
                :widget="widget"
                :date="start_date"
            />
        </column>
    </columns>
    
    <table class="table is-fullwidth">
        <thead>
            <tr>
                <th v-for="column in report.columns" :key="column.name">{{ column.label }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(row, index) in rows" :key="index">
                <td v-for="column in report.columns" :key="column.name">{{ row[column.name] | ucwords }}</td>
            </tr>
        </tbody>
    </table>
</loader>    
</template>
<script>
import { mapGetters } from 'vuex'
import { report as backend } from '@/api'
import { build_report_filtering_date } from './utils'

export default {

    data: () => ({
        loading: true,
        rows: [],
        start_date: ''
    }),

    mounted() {
        backend.loadReportData({
            report_id: this.report.id,
            type: this.report.type,
            start_date: ''
        }, ({ data }) => {
            this.rows = data
            this.start_date = build_report_filtering_date(this.report.range)
            this.loading = false
        }, () => this.loading = false)
    },

    methods: {
        updateStartDate(date) {
            this.start_date = build_report_filtering_date(this.report.range, date)
            this.loadReportData()
        },
        loadReportData() {
            backend.loadReportData({
                report_id: this.report.id,
                type: this.report.type,
                start_date: this.start_date
            }, ({ data }) => {
                this.rows = data
                this.loading = false
            }, () => this.loading = false)
        }
    },

    computed: {
        ...mapGetters('report', [
            'report'
        ])
    }
}
</script>